body, html {
  margin: 0;
  padding: 0;
 /* background: linear-gradient(45deg, #ffcc99, #ffffcc, #99ff99, #cc99ff, #99ccff, #ff99cc);
  background-size: 300%;
  animation: changeColor 10s linear infinite alternate;*/
  background-color: #99ccff;
  
  
}
*{
  color: white;
}
  
@keyframes changeColor {
  0% {
    background-position:  0%;
  }
  20% {
    background-position:  20%;
  }
  40% {
    background-position:  40%;
  }
  60% {
    background-position:  60%;
  }
  80% {
    background-position:  80%;
  }
  100% {
    background-position:  100%;
  }

}

 

body{
  font-family: "Space Grotesk", sans-serif;
 
 

}.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  text-align: center;
}

.loading img {
  width: 80%; /* Tornar a imagem responsiva */
  max-width: 300px; /* Limite máximo de tamanho */
  height: auto;
  animation: spin 5s linear infinite; /* Animação de rotação */
}

/* Definição da animação de rotação */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
